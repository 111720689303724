exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-appraisal-request-jsx": () => import("./../../../src/pages/appraisal_request.jsx" /* webpackChunkName: "component---src-pages-appraisal-request-jsx" */),
  "component---src-pages-art-advisor-jsx": () => import("./../../../src/pages/art-advisor.jsx" /* webpackChunkName: "component---src-pages-art-advisor-jsx" */),
  "component---src-pages-art-collection-api-jsx": () => import("./../../../src/pages/art-collection-api.jsx" /* webpackChunkName: "component---src-pages-art-collection-api-jsx" */),
  "component---src-pages-art-market-alerts-jsx": () => import("./../../../src/pages/art-market-alerts.jsx" /* webpackChunkName: "component---src-pages-art-market-alerts-jsx" */),
  "component---src-pages-art-master-jsx": () => import("./../../../src/pages/art-master.jsx" /* webpackChunkName: "component---src-pages-art-master-jsx" */),
  "component---src-pages-artist-jsx": () => import("./../../../src/pages/artist.jsx" /* webpackChunkName: "component---src-pages-artist-jsx" */),
  "component---src-pages-auction-results-database-jsx": () => import("./../../../src/pages/auction-results-database.jsx" /* webpackChunkName: "component---src-pages-auction-results-database-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-consign-jsx": () => import("./../../../src/pages/consign.jsx" /* webpackChunkName: "component---src-pages-consign-jsx" */),
  "component---src-pages-financial-reporting-jsx": () => import("./../../../src/pages/financial-reporting.jsx" /* webpackChunkName: "component---src-pages-financial-reporting-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inventory-management-jsx": () => import("./../../../src/pages/inventory-management.jsx" /* webpackChunkName: "component---src-pages-inventory-management-jsx" */),
  "component---src-pages-museum-jsx": () => import("./../../../src/pages/museum.jsx" /* webpackChunkName: "component---src-pages-museum-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-private-room-[id]-index-jsx": () => import("./../../../src/pages/private_room/[id]/index.jsx" /* webpackChunkName: "component---src-pages-private-room-[id]-index-jsx" */),
  "component---src-pages-private-room-index-jsx": () => import("./../../../src/pages/private_room/index.jsx" /* webpackChunkName: "component---src-pages-private-room-index-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-valuation-jsx": () => import("./../../../src/pages/valuation.jsx" /* webpackChunkName: "component---src-pages-valuation-jsx" */),
  "component---src-templates-artist-jsx": () => import("./../../../src/templates/artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-gallery-jsx": () => import("./../../../src/templates/gallery.jsx" /* webpackChunkName: "component---src-templates-gallery-jsx" */),
  "component---src-templates-museum-jsx": () => import("./../../../src/templates/museum.jsx" /* webpackChunkName: "component---src-templates-museum-jsx" */)
}

